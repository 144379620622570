.public_login_wrapper{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ebebeb;
}
.public_login_container{
    width: 400px;
    padding: 80px 20px 50px 20px;
    background-color: white;
    position: relative;
    border-radius: 10px;
}
.public_login_title{
    position: absolute;
    top: -30px;
    left: 20px;
    right: 20px;
    background-image: linear-gradient(195deg, #B92E34, #7c191e);
    border-radius: 10px;
    padding: 20px 0px;
}
.public_login_title h3{
    font-size: 24px;
    text-align: center;
    font-weight: 500;
    color: white;
}