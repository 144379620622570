.primary_input_container{
    padding: 10px 0px;
}
.primary_input_container label{
    width: 100%;
}
.primary_input_container input{
    margin: 5px 0px;
    width: 100%;
    outline: none;
    border: 2px solid #ebebeb;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 14px;
}
.primary_input_container input:focus{
    border: 2px solid rgb(26, 115, 232);
}