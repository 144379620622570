.primary_button_container{
    padding: 10px 0px;
}
.primary_button_container button{
    outline: none;
    border: none;
    background-image: linear-gradient(195deg, #B92E34, #7c191e);
    color: white;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 20px;
    text-align: center;
    border-radius: 5px;
    width: 100%;
    cursor: pointer;
}