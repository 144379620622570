.shopifymessage_wrapper{
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ebebeb;
}
.shopifymessage_container{
    width: 40vw;
    padding: 100px;
    border-radius: 10px;
    background-color: white;
}
.shopifymessage_icon{
    display: flex;
    justify-content: center;
}
.shopifymessage_icon svg{
    width: 100px;
    height: 100px;
}
.shopifymessage_icon svg path{
    fill: green;
}
.shopifymessage_container p{
    text-align: center;
    font-size: 18px;
    padding: 20px 0px;
}